import React from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    return (
        <div className='pp body' > 
            <h1>Política de Privacidade</h1>

<h3>Introdução</h3>
<p>
    A SVAAS Wellness Private Limited (“SVAAS”) e cada uma de suas afiliadas e entidades subsidiárias (coletivamente “SVASS”) estão comprometidas em manter a precisão, confidencialidade e segurança dos Dados Pessoais dos usuários de sua Plataforma, Alivius <a href="/">https://www.myalivius.com/</a>, doravante (“Plataforma” ou “Site”). Esta Política de Privacidade da Plataforma (“Política”) foi preparada para explicar quais dados pessoais são coletados, como são coletados, usados, divulgados e outras informações relacionadas aos direitos dos usuários, em conexão com o uso da Plataforma.
</p>

<p>
    Antes de registrar sua conta na Plataforma, certifique-se de que leu, entendeu e aceitou todas as informações desta Política. Caso você não concorde com qualquer parte desta Política, não registre uma Conta nem acesse a Plataforma. A conclusão do seu registro ou a continuação do uso da Plataforma indicará sua aceitação dos termos desta Política. Devido ao fato de que nossa Política pode mudar a qualquer momento, recomendamos que você revise esta Política de tempos em tempos para verificar se há atualizações. 

</p>

<h3>Definições</h3>
<p>
    <h5>Conta:</h5>Registro de conta que permite ao Usuário acessar e usar a Plataforma ou o Site.
</p>

<p>
    <h5>Política de Privacidade:</h5>   refere-se a esta Política de Privacidade.

</p>
<p>
    <h5>Usuário ou Você:</h5> significa  qualquer pessoa física que faça uso da Plataforma, a qualquer título.
</p>

<p>
    <h5>Site ou Plataforma:</h5> Solução de software baseada na Internet fornecida via desktop/portal da web e/ou via aplicativo móvel, <a href="/">https://www.myalivius.com/</a>, ao pessoal autorizado dos prestadores de serviços de saúde e seus pacientes e cuidadores, como uma Plataforma de software, de propriedade da SAAS, e usada para melhorar a comunicação entre os pacientes e as entidades dedicadas aos serviços de saúde.

</p>

<h3>Legislação aplicável</h3>

<p>
    Legislação brasileira, principalmente, Leis Federais nº 13.709/2018 e nº 12.965/2014; Decreto nº 8.771/2016; Constituição Federal Brasileira; Código Civil Brasileiro; Código do Consumidor Brasileiro; Decreto nº 7.963/2013, conforme alterado de tempos em tempos, e qualquer futura legislação brasileira ou regulamentação aplicável emitida pela Autoridade Brasileira de Proteção de Dados (“ANPD”) e/ou pela Agência Nacional de Vigilância Sanitária (“ANVISA”) ou outra autoridade governamental competente. 
</p>

<h3>Princípios </h3>

<p>
    O tratamento dos Dados Pessoais fornecidos pelo Usuário basear-se-á, entre outros, nos princípios da finalidade, essencialidade e transparência.
</p>

<p>
    Estamos comprometidos em proteger sua privacidade e tomamos e tomaremos medidas razoáveis para proteger a privacidade e a confidencialidade dos Dados Pessoais do Usuário, fornecidos a nós por meio da Plataforma.
</p>

<h3>
    Tipos de Dados Pessoais Coletados
</h3>

<p class="pbeforeul">Coletamos informações que você, seu cuidador ou o pessoal autorizado do serviço de saúde nos fornece voluntariamente através deste Site, incluindo:</p>
<ul>
    <li>
        Dados de identificação, como nome, data de nascimento, idade, nacionalidade, sexo, número de telefone, endereço, endereço de e-mail, número de previdência social, número de registro de contribuinte para fins de registro.
    </li>
    <li>
        Dados relacionados a casos específicos para os quais você foi contratado aplicáveis ao pessoal autorizado do serviço de saúde.
    </li>
    <li>
        Dados relacionados ao registro de som e imagem em conexão com os serviços de saúde que estão sendo prestados a você.
    </li>
    <li>
        Dados pessoais relacionados à atividade de navegação do Usuário durante o uso do Site, incluindo endereço IP, tipo de navegador e plataforma operacional, cookies, duração do uso, data e hora de acesso, URL, idioma utilizado, localização geográfica.
    </li>
    <li>
        Os Dados Pessoais Sensíveis, que são aqueles relacionados à origem racial ou étnica, dados relacionados à saúde ou vida sexual, informações genéticas ou biométricas, serão coletados somente se você, seu cuidador ou o pessoal autorizado do serviço de saúde os fornecerem voluntariamente diretamente por meio de canais apropriados no Site, conforme exigido pela lei aplicável, para a prestação de serviços de saúde solicitados por Você.
    </li>
    <li>
        Outros Dados Pessoais e informações fornecidas pelo Usuário para solicitar consultas, comentários, reclamações, preferências de contato e quaisquer outras informações fornecidas por Você que não revelem sua identidade específica.
    </li>
</ul>

<p>Devido ao fato de que a Plataforma/Site processa apenas dados selecionados pelo prestador de serviços de saúde, por meio de seu pessoal autorizado, a SVAAS não atua como controladora dos Dados Pessoais processados por meio da Plataforma/Site.</p>

<p>Portanto, o prestador de serviços de saúde por meio da Plataforma, de propriedade da SVAAS, assume a condição de controlador, tendo autonomia em relação ao tratamento de Dados Pessoais e Dados Pessoais Sensíveis, podendo escolher a finalidade para a qual são tratados, a categoria de dados a serem coletados, por quanto tempo tais dados são retidos, e outras questões diretamente relacionadas ao tratamento de Dados Pessoais e Dados Pessoais Sensíveis.</p>

<p>Observe que os dados coletados e processados pelo nosso Site são controlados pelo prestador de serviços de saúde ("nossos Clientes"), que os usam, divulgam e protegem, de acordo com suas respectivas políticas de privacidade e proteção de dados.</p>

<p>Não obstante, a SVAAS será controladora de dados apenas para os fins de: (i) hospedar a Plataforma em servidores externos; (ii) prestar serviços de suporte e manutenção aos nossos Clientes; (iii) abordar problemas técnicos, bugs e falhas no Site; (iv) identificar oportunidades para aprimorar recursos e otimizar a experiência do Usuário; (v) adaptar recursos, interfaces e funcionalidades de forma personalizada; e (vi) analisar tendências de uso para planejar a escalabilidade.</p>

<p>
    Como processador de dados, embora não controlemos os dados que são coletados diretamente de nossos Clientes, adotamos várias práticas de segurança para garantir que as informações coletadas em nossa Plataforma sejam processadas de maneira segura de acordo com a lei aplicável. 
</p>

<p>
    Para saber mais sobre como coletamos cookies durante sua visita à nossa Plataforma/Site, consulte nossa LINK PARA A POLÍTICA DE COOKIES DE SVASS.
</p>

<h3>Quando coletamos os seus Dados Pessoais</h3>

<ul>
    <br />
    <li>Após a criação de uma conta por Você ou pelo seu cuidador;</li>
    <li>Quando Você, seu cuidador ou o pessoal autorizado do prestador de serviços de saúde carrega quaisquer dados diretamente na Plataforma; </li>
    <li>Enquanto Você estiver usando o Site e/ou os produtos e alguns dos serviços disponibilizados no Site;</li>
    <li>Informações que obtemos do seu uso do Site;</li>
    <li>Quando Você, seu cuidador ou a pessoa autorizada usar o chat disponível na Plataforma;</li>
    <li>Quando Você se cadastra para receber e-mails, alertas ou outras informações; e 
    </li>
    <li>De terceiros.</li>
</ul>

<p>Você, seu cuidador e o pessoal autorizado do prestador de serviços de saúde que carrega os dados, conforme o caso, são exclusivamente responsáveis pela precisão, clareza, relevância, atualização e integridade dos Dados Pessoais fornecidos.</p>

<p>As informações enviadas devem ser mantidas atualizadas para garantir que os serviços permaneçam relevantes e cheguem até você.</p>

<h3>Uso das informações</h3>

<p>Ao usar este Site ou nossos serviços, Você reconhece que nos autorizou a usar seus Dados Pessoais para os seguintes fins:</p>

<table>
    <tr>
        <th>Objetivos</th>
        <th>Base Legal</th>
    </tr>
    <tr>
        <td>Prestar serviços e fornecimento da Plataforma/Site</td>
        <td>cumprimento de obrigações legais e a execução de um contrato ou procedimentos preliminares</td>
    </tr>
    <tr>
        <td>Para melhorar nosso Site e nossos serviços de suporte e manutenção para nossos Clientes e Usuários da Plataforma</td>
        <td>cumprimento contratual, o exercício regular de direitos em processo judicial ou administrativo, o interesse legítimo e o cumprimento de obrigações legais, conforme aplicável</td>
    </tr>
    <tr>
        <td>Mensagens de contato e marketing</td>
        <td>interesse legítimo em informar o titular dos dados sobre seus temas de interesse, bem como sobre as atividades oferecidas pela Plataforma </td>
    </tr>
    <tr>
        <td>Responder a solicitações, reclamações ou prestar esclarecimentos aos titulares dos dados</td>
        <td>interesse legítimo, cumprimento de obrigações legais e cumprimento contratual, conforme aplicável</td>
    </tr>
    <tr>
        <td>Realizar análises estatísticas, garantindo, sempre que possível, a anonimização dos Dados Pessoais
        </td>
        <td>interesse legítimo da SVAAS e/ou de seu Cliente</td>
    </tr>
    <tr>
        <td>Medição do nível de satisfação dos Usuários da Plataforma</td>
        <td>Consentimento por escrito</td>
    </tr>
</table>

<p>
    Os destinatários de mensagens relacionadas a marketing podem, a qualquer momento, optar por não receber esses tipos de comunicações.
</p>

<p>
    Visando a melhoria contínua da Plataforma, podemos ocasionalmente acionar determinados atributos na Plataforma controlados por nossos Clientes, que coletam e armazenam informa,ções de uso no sistema, de modo a analisar a interação do Usuário com as ferramentas e corrigir eventuais erros ou bugs.
    Utilizaremos as suas Informações Pessoais apenas para os fins para os quais foram coletadas. Qualquer alteração na finalidade do tratamento dos Dados Pessoais ou Transferência Internacional de Dados Pessoais será informada ao titular dos dados, ainda que para fins de cumprimento de obrigações legais e/ou regulatórias impostas sobre a SVAAS ou para o cumprimento de seus interesses legítimos.

</p>

<h3>Crianças</h3>

<p>
    A SVAAS não pretende coletar Dados Pessoais de crianças e adolescentes e somente o fará com base nas leis aplicáveis e no melhor interesse dos titulares dos dados, mediante satisfação de proteção integral obrigatória e obtenção de consentimento específico dos pais ou responsáveis legais, limitando o tratamento de tais Dados Pessoais ao mínimo necessário.
</p>

<p>
    Os pais ou responsáveis legais podem, a qualquer momento, exercer os direitos concedidos pela lei aplicável aos titulares dos Dados Pessoais para revisar, corrigir, atualizar, limitar e excluir, ou reter o consentimento para a coleta, uso e divulgação de tais Dados Pessoais. A SVAAS envidará esforços razoáveis para excluir essas informações ou anonimizá-las.
</p>

<p>
    Caso os Dados Pessoais das crianças sejam processados por nós devido ao uso da Plataforma por nossos Clientes, estes últimos são responsáveis, como controladores, pela obtenção do devido consentimento por escrito, conforme regulamentado na lei aplicável, interagindo diretamente com os pais ou responsáveis legais das crianças para esse fim. 
</p>


<h3>
    Compartilhamento de Dados Pessoais
</h3>

<p>Comprometemo-nos a não divulgar, salvo disposição em contrário nesta Política de Privacidade, os Dados Pessoais fornecidos por Você a qualquer pessoa, a menos que seja previsto nos seguintes casos:</p>

<ul>
    <li>Com as afiliadas da SVAAS e demais empresas do seu grupo, com parceiros de negócios, agentes e subcontratados localizados no Brasil ou no exterior, tudo em consonância com a presente Política e a legislação aplicável para fins de cumprimento de obrigações legais e regulatórias impostas sobre a SVAAS e para o cumprimento de seu interesse legítimo, quando atuando como controladora de dados.</li>
    <li>
        Com funcionários e parceiros de negócios (terceiros e subcontratados) para o processamento dos Dados Pessoais do Usuário para auxiliar na execução do Site e fornecer nossos serviços, incluindo, por exemplo, provedores de hospedagem na web e provedores de TI (AWS, T Mobile etc.), para desenvolvimento de sistemas e suporte técnico, com auditores externos, consultores administrativos e jurídicos para outros serviços.
    </li>
    <li>
        Investigar, prevenir ou agir em relação a atividades ilegais, suspeita de fraude, situações relacionadas a potenciais ameaças a qualquer segurança individual.
    </li>
    <li>
        Em caso de fusão, aquisição ou transferência de todos ou parte significativa dos ativos da SVAAS.
    </li>
    <li>
        Mediante autorização por escrito dos Usuários, conforme venha a ser exigido pela legislação aplicável.
    </li>
    <li>
        Cumprir obrigações legais, judiciais ou regulatórias (conforme exigido por qualquer autoridade competente, incluindo a Agência Nacional de Saúde (“ANVISA”) ou Food Drugs e FDA; e 
    </li>
    <li>
        Proteger os direitos, a segurança e a propriedade da SVAAS na medida permitida por lei.
    </li>
</ul>

<p>
    Definimos responsabilidades e processos para avaliar e responder a solicitações de dados de autoridades policiais ou Governamentais. O envio será feito de acordo com as regras aplicáveis.
</p>

<p>
    Os prestadores de serviços terceirizados são obrigados a usar medidas de segurança apropriadas para proteger a confidencialidade e a segurança das Informações Pessoais.
</p>

<p>
    Na hipótese de a SVAAS compartilhar Dados Pessoais com terceiros localizados fora do Brasil, deverá garantir que essa transferência internacional de Dados será realizada de acordo com o disposto na legislação aplicável, de forma adequada, aderente às suas finalidades e dentro dos limites do que for efetivamente necessário para atender a esses requisitos. 
</p>

<p>
    A SVAAS também pode compartilhar seus Dados agregados com os de outras pessoas, bem como Dados agregados que não incluem informações de identificação pessoal ou são anonimizados.
</p>

<p>
    Informamos que a SVAAS pode transferir os seus Dados Pessoais para jurisdições estrangeiras com leis de proteção de dados menos rigorosas do que as leis do seu país. 
</p>

<p>
    Não venderemos seus Dados Pessoais a terceiros. 
</p>

<h3>Comunicação</h3>

<p>A SVAAS e o prestador de serviços de saúde comunicar-se-ão com Você (por e-mail, serviço postal, correio, serviços de mensagens móveis e ramais de telefone ou redes sociais) quando concordar em receber essa comunicação ou quando os requisitos operacionais ou regulamentares exigirem que o façamos.
    Podemos ainda nos comunicar com Você por meio de fornecedores terceirizados. Você terá a opção de cancelar a inscrição para receber essa comunicação por e-mail.
    </p>

<h3>Links / Ofertas de Terceiros</h3>

<p>A SVAAS pode permitir que terceiros ofereçam Serviços baseados em assinatura e/ou registro através do Site, Aplicativo ou outras plataformas (por exemplo, WhatsApp, Twitter etc.). A SVAAS Wellness não será responsável por quaisquer ações ou políticas de tais terceiros e os Usuários são solicitados a verificar a política de privacidade aplicável e familiarizar-se com os termos e condições de uso de tais prestadores de serviços, ao fornecer informações pessoalmente identificáveis.</p>

<p>A SVAAS não será responsável por qualquer variação nas características/benefícios oferecidos por tais terceiros e não assumirá, em nenhum caso, garantia sobre a eficiência ou eficácia de tais ofertas.
</p>

<h3>
    Retenção dos Dados 
</h3>

<p>
    Usamos centros de dados e serviços de nuvem de terceiros para armazenar seus dados com segurança e minimizar a perda de dados. As salvaguardas de segurança e cláusulas contratuais relevantes, conforme exigido pelas Leis relevantes, são cumpridas durante o armazenamento dos seus dados.
</p>

<p>
    Manteremos apenas os Dados Pessoais e/ou Dados Pessoais Sensíveis necessários para gerenciar os serviços que prestamos e para os fins comerciais que somos legítimos e indispensáveis, pelo tempo que for razoavelmente necessário ou permitido para cumprir a finalidade para a qual tais informações foram coletadas ou pelo tempo necessário para cumprir os regulamentos e a lei aplicável.
</p>

<h3>
    Alterações à Política de Privacidade
</h3>

<p>
    Esta Política de Privacidade pode ser alterada, modificada, substituída por uma nova política, a qualquer momento, a nosso exclusivo critério. A Política de Privacidade revisada/suas alterações entrarão em vigor a partir da data indicada. Os clientes são solicitados a verificar periodicamente os termos e condições desta Política de Privacidade. Os Usuários e a SVAAS não serão, em nenhuma circunstância, responsabilizados por tais lapsos por parte do Cliente. 
</p>

<h3>
    Segurança da Informação
</h3>

<p>
    Tomamos precauções razoáveis para proteger os dados processados no Site contra acesso não autorizado ou alteração, divulgação ou destruição não autorizada das informações que possuímos. 
</p>

<ul>
    <li>
        Criptografamos muitos de nossos Serviços usando os certificados SSL. 
    </li>
    <li>
        Fornecemos acesso com base em função ao pessoal autorizado de nossos Clientes que precisa fazer login em nossos sistemas/ aplicativos.
    </li>
    <li>
        Fornecemos autenticação com base em senha para Usuários que fazem login em nosso Site.
    </li>
    <li>
        Analisamos as nossas práticas de coleta, armazenamento e processamento de informações, para nos protegermos contra o acesso não autorizado a sistemas.
    </li>
    <li>
        Permitimos o acesso a informações pessoalmente identificáveis aos nossos funcionários, contratados e agentes que estão sujeitos a rigorosas obrigações contratuais de confidencialidade para fins de processamento de dados.
    </li>
    <li>
        Realizamos avaliações de vulnerabilidade do nosso Site e sistemas de processamento de dados periodicamente.
    </li>
</ul>
<br />

<h3>
    Aviso
</h3>

<p>
    As informações, o conteúdo e os materiais fornecidos na Plataforma/Site são "no estado em que se encontram" e a SVAAS não faz nenhuma declaração ou garantia expressa ou implícita em relação a sua precisão, adequação ou integridade.
</p>

<h3>
    Encarregado de Proteção de Dados de Informações de Contato ("DPO")
</h3>

<p>Caso você precise de mais esclarecimentos sobre o conteúdo desta Política de Privacidade ou sobre o uso de seus Dados Pessoais, entre em contato com indicar DPO da SVAAS dataprivacy@svaaswellness.com 
</p>

<h3>Direitos dos Titulares dos Dados</h3>

<p>Os titulares dos dados têm o direito de, a qualquer momento, exercer os direitos concedidos pela legislação aplicável, incluindo, solicitar o acesso, atualizar e retificar os seus dados, o direito de revogar o seu consentimento ou solicitar a eliminação dos seus dados, solicitar prova do consentimento dado, solicitar a portabilidade dos seus próprios dados para outro controlador e o direito de apresentar reivindicações perante a autoridade de proteção de dados.</p>

<p>Caso o exercício de quaisquer direitos do Usuário resulte em descumprimento das obrigações da SVAAS, nos termos das leis, códigos de conduta, posturas ou regras aplicáveis, a SVAAS não atenderá à solicitação do Usuário. Em qualquer caso, é garantido ao Usuário o pedido de bloqueio dos seus Dados Pessoais para posterior tratamento. </p>

<h3>Procedimento para Acessar seus Dados Pessoais</h3>

<p>Você tem acesso gratuito aos seus Dados Pessoais armazenados nas nossas bases de dados, ao tratamento a que estão sujeitos e às finalidades pretendidas, além das indicadas abaixo:</p>

<ul>
    <li>As solicitações devem ser enviadas por escrito e por e-mail ao DPO. 
    </li>
    <li>O pedido será analisado para verificar a sua identidade. Se a solicitação for enviada por alguém que não seja Você (o titular dos dados) e a pessoa não puder fornecer evidências que estejam agindo legitimamente em seu nome, como seu cuidador ou responsável legal, a solicitação será rejeitada.</li>
</ul>

<p>Você pode apresentar uma reivindicação quando considerar que as informações incluídas nas nossas bases de dados devem ser retificadas, atualizadas ou eliminadas, ou quando avisar previamente da alegada violação dos seus direitos. </p>

<p>Esta Política de Privacidade foi atualizada pela última vez em 10/20/2023.</p>
        </div>
    )
}

export default PrivacyPolicy