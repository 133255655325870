import React from 'react'
import Header from './components/Header/Header'
import Landing from './components/Landing/Landing'
import Intro from './components/Intro/Intro'
import Hospital from './components/Hospitals/Hospital'
import Patient from './components/Patients/Patient'
import Footer from './components/Footer/Footer'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import Terms from './components/Terms/Terms'

import { BrowserRouter, Routes, Route } from 'react-router-dom'


import './App.css'
import { PrivacyPolicyEn } from './components/PrivacyPolicy/en/pp-en'

const App = () => {
  return (

    <BrowserRouter>
    <Routes>
      <Route path="/" element={<>
      <Header />
      <Landing />
      <Intro />
      <Hospital />
      <Patient />
      <Footer />

    </>}> </Route>

      <Route path="/privacy-policy" element={<PrivacyPolicy />} ></Route>
      <Route path="/en/privacy-policy" element={<PrivacyPolicyEn />} ></Route>
      <Route path='/terms-and-conditions' element={<Terms/>} ></Route>
     </Routes>
      </BrowserRouter>

    
  )
}

export default App